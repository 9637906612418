import quirkImage from '../assets/portfolio-images/quirkScreenshot.png';
import cuebandImage from '../assets/portfolio-images/cuebandScreenshot.png';
import oleniumImage from '../assets/portfolio-images/oleniumScreenshot.png';
import onepageImage from '../assets/portfolio-images/onepageScreenshot.png';
import dashpointImage from '../assets/portfolio-images/dashpointScreenshot.png';

export default function Portfolio() {
  const portfolioItems = [
    {
      title: 'DashPoint',
      description:
        'A community-driven running app designed to add spontaneity and excitement to the running experience, inspired by the immediacy found in apps like BeReal. Perfect for runners looking to break away from routine or traditional clubs, Dashpoint announces the 5k route location just three hours before each run, adding an element of surprise. Participants only discover the specific route upon arrival, building anticipation and gamifying the experience.',
      image: dashpointImage,
      link: 'https://dashpoint.uk',
    },
    {
      title: "It's One Page",
      description:
        'A web design company specialising in creating 1 page websites for clients in the North East of England, the site was built using React, Tailwind CSS and is hosted using Cloudflare Pages. This project was created by myself and a friend in order to turn our passion for web development into a business. This was later merged in a larger project called Olenium.',
      image: onepageImage,
      link: 'https://olenium.co.uk',
    },
    {
      title: 'Olenium',
      description:
        'A freelance platform that connects freelancers with clients. The platform is designed to make the process of hiring a freelancer as easy as possible. This is the biggest project I have been apart of so far, it is an ambitious project that involved lots of different design and development techniques.',
      image: oleniumImage,
      link: 'https://olenium.co.uk',
    },
    {
      title: "Letterboxd Quirk'o'Meter",
      description:
        "A web app that uses Letterboxd data to calculate a user's Quirk Score based on their film ratings. The Quirk Score is a measure of how unconventional a user's film tastes are compared to the average user. This project was built using React and Chakra UI. The app is hosted on Cloudflare Pages, the backend runs using AWS Lambda and the source code is available on GitHub.",
      image: quirkImage,
      github: 'https://github.com/MitchelMckee/letterboxd',
      link: 'https://quirk.mjkm.co.uk/',
    },
    {
      title: 'CueBand for WatchOS',
      description:
        "An Apple Watch app that allows people with Parkinson's Disease to better self-manage their symptoms in unobtrusive ways. The app sends vibrotactile cues to the user's wrist to help them with sialorrhea, this project was completed in conjunction with the CueBand research project at Northumbria University. The source code is available on GitHub.",
      image: cuebandImage,
      github: 'https://github.com/MitchelMckee/CueBand_WatchOS',
      link: 'https://cue.band',
    },
  ];

  return (
    <div className="p-4">
      <h1 className="pb-8 text-center font-PPEditorial text-4xl italic text-main-bg-colour md:text-6xl lg:text-8xl">
        Projects
      </h1>
      <div className="flex flex-col items-center gap-4">
        {portfolioItems.map((item, index) => (
          <a href={item.link} target="_blank" rel="noreferrer" key={index}>
            <div className="flex w-full max-w-4xl flex-col items-center text-balance text-main-bg-colour transition-transform duration-300 ease-in-out hover:scale-105 md:w-3/4 lg:flex-row">
              <div className="w-full flex-grow p-4 md:w-8/12">
                <div className="text-center font-PPEditorial text-xl italic md:text-start md:text-2xl">
                  {item.title}
                </div>
                <p className="font-PPEditorial text-sm md:text-base">
                  {item.description}
                </p>
              </div>
              {item.image && (
                <div className="w-full md:w-4/12">
                  <img
                    src={item.image}
                    alt={item.title}
                    className="h-auto w-full rounded-md shadow-md"
                  />
                </div>
              )}
            </div>
          </a>
        ))}
      </div>
    </div>
  );
}
